import { Link } from "gatsby"
import React from "react"
import Seo from "../components/seoPage"
import Bread from "../components/breadPage"

const Diclaimer = () => {
  return (
    <main>
      <Seo
        title="Disclaimer kebijakan hajebo blog"
        diskripsi="Disclaimer kebijakan tentang hajebo. Mohon baca terlibih dahulu sebelum melanjutkan membaca"
        slug="/disclaimer/"
        tags="disclaimer, kebijakan, hak cipta"
        imageUrl="https://hajebo.com/logo.png"
      />
      <Bread nama2="disclaimer" />
      <div className="mx-4 md:mx-10 lg:mx-20">
        <h1 className="text-center m-8 md:m-10 text-3xl font-extrabold">
          Disclaimer
        </h1>
        <div className="text-lg">
          <p>
            <Link className="font-bold" to="/">
              Hajebo.com
            </Link>{" "}
            menyajikan informasi yang merupakan hasil tulisan sendiri dengan
            mengumpulkan informasi dari berbagai sumber. Semua informasi yang
            dikutip dari situs lain akan dicantumkan tautan sumbernya.Kami
            berusaha sebaik mungkin agar tulisan yang ditampilkan benar-benar
            akurat. Meskipun begitu, sangat diharapkan kebijaksanaan dari para
            pembaca untuk meneliti terlebih dahulu semua informasi yang Anda
            dapatkan dari situs ini dan tidak ‘menelannya’ secara mentah-mentah.
            Semua kerugian yang diakibatkan dari kelalaian pembaca berada di
            luar tanggung jawab kami.
          </p>
          <h2 className="text-xl my-4 font-semibold">Penyalinan informasi</h2>
          <p>
            hajebo.com memperbolehkan semua pihak untuk mengambil referensi dari
            tulisan yang kami buat dengan syarat mencantumkan tautan dari situs
            ini. Pastikan juga pengambilan referensi tersebut tidak dalam bentuk
            penyalinan (copy-paste) secara langsung karena hal tersebut
            merupakan pelanggaran hak cipta. Penyalinan tidak boleh digunakan
            untuk komersil ataupun digunakan untuk mendapatkan keuntungan baik
            materil maupun non materil tanpa se-ijin dan sepengetahuan kami.
          </p>
          <h2 className="text-xl my-4 font-semibold">Pelanggaran Hak Cipta</h2>
          <p>
            Hajebo.com tidak bertanggung jawab atas kerugian maupun potensi
            kerugian yang terjadi akibat kesamaan konten dan aset digital yang
            memiliki perlindungan kekayaan intelektual. Sebagaimana jika anda
            merasa yakin bahwa kami memiliki aset digital yang anda miliki maka
            silahkan hubungi kami untuk penghapusan konten dan aset digital
            tersebut
          </p>
          <p className="my-4">
            Apabila Anda membutuhkan informasi lainnya atau memiliki pertanyaan
            mengenai dokumen kebijakan ini, silahkan hubungi kami melalui email
            ke alamat{" "}
            <a className="font-bold" href="mailto:hajebo.com@gmail.com">
              hajebo.com@gmail.com
            </a>{" "}
          </p>
        </div>
      </div>
    </main>
  )
}

export default Diclaimer
